import React from "react"
import TabNavigation from "../../../components/TabNavigation"
import LayoutBasic from "../../../components/LayoutBasic"
import BasicText from "../../../components/BasicText"
import Info2column from "../../../components/Info2column"
import OverflowFrame from "../../../components/OverflowFrame"
import css from "../stylesheets/basicInformation.module.scss"
import Seo from "../../../components/Seo"
import CenterContentLayout from "../../../components/CenterContentLayout"

function Kaigo(props) {
  return (
    <div>
      <Seo title={`ABOUT KAIGO | BASIC INFORMATION`} lang={`en`} />

      <LayoutBasic lang={`en`}>
        <CenterContentLayout>
          <BasicText>
            <h1>BASIC INFORMATION</h1>
            <p>
              What’s Japan like? How to get a job in the nursing care field in
              Japan?
              <br />
              Let us introduce the basic information about Japan and four
              different types of status of residence to work as a care worker.
            </p>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <TabNavigation
            links={[
              { title: `ABOUT JAPAN`, link: `/en/basic-information/` },
              {
                title: `ABOUT KAIGO`,
                link: `/en/basic-information/kaigo/`,
                current: true,
              },
            ]}
          />
        </CenterContentLayout>
        <CenterContentLayout>
          <BasicText>
            <h2 className={css.BasicInfoH2}>ABOUT KAIGO</h2>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <Info2column
            id={`section-1`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_facility.svg`}
                alt="Nursing Care Benefits"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <h3>
                Nursing Care Facilities and Nursing Care Benefits in Japan
                <sup>※</sup>
              </h3>
              <dl className={css.BasicInfoSummaryDlBlock}>
                <dt>Long-Term Care Insurance Facilities:</dt>
                <dd>13,409</dd>
              </dl>
              <dl className={css.BasicInfoSummaryDlBlock}>
                <dt>Nursing Care Service Companies:</dt>
                <dd>363,452</dd>
              </dl>
              The number of facilities includes intensive care homes for the
              elderly, long-term health care centers for the elderly and
              sanatorium medical facilities for the elderly requiring long-term
              care. The number of companies includes all the nursing care
              service companies except for long-term care insurance facilities,
              and it is increasing every year. The nursing care benefits, which
              also indicates the usage of nursing care service, is also
              increasing.
              <div className={`mt20`}>
                <ul className={`notes`}>
                  <li>
                    <i>※</i>
                    <span>
                      Results of ‘Survey of Institutions and Establishments for
                      Long-term Care’ (2018), ‘Annual Report on Nursing Care
                      Insurance: Business Report’ (2018) by the Japan Ministry
                      of Health, Labor and Welfare
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Info2column>
        </CenterContentLayout>
        <CenterContentLayout>
          <div id={`section-2`} className={css.BasicInfoKaigoSystem}>
            <BasicText>
              <h3>Status of Residence for Nursing Care</h3>
              <h4>Features of Four Systems for Status of Residence</h4>
              <p>
                To work in the nursing care field, there are four systems for
                status of residence: ‘EPA (Economic Partnership Agreement)’
                started in 2008; ‘Nursing Care’ and ‘Technical Intern Training’
                started in 2017; Specified Skilled Worker started in 2019.
              </p>

              <OverflowFrame>
                <table className={css.BasicStickyTable}>
                  <thead>
                    <tr>
                      <th>
                        <i></i>
                      </th>
                      <th>
                        <i>EPA</i>
                      </th>
                      <th>
                        <i>Nursing Care</i>
                      </th>
                      <th>
                        <i>Technical Intern Training</i>
                      </th>
                      <th>
                        <i>Specified Skilled Worker</i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <i>Status of Residence</i>
                      </th>
                      <td>
                        <i>'Designated Activities'</i>
                      </td>
                      <td>
                        <i>
                          'Nursing Care' <sup>※4</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          'Technical Intern Training (i)-(iii)'<sup>※7</sup>
                        </i>
                      </td>
                      <td>
                        <i>'Specified Skilled Worker (i)'</i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>Purpose of System</i>
                      </th>
                      <td>
                        <i>
                          Acceptance for the purpose if acquiring Certified Care
                          Worker national qualification and enhancement of
                          international cooperation
                        </i>
                      </td>
                      <td>
                        <i>
                          Acceptance of international workers in
                          specialist/technical areas
                        </i>
                      </td>
                      <td>
                        <i>Transfer of skills from Japan to another country</i>
                      </td>
                      <td>
                        <i>
                          Acceptance of foreign nationals with specific
                          expertise/skills to address labor shortage
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>Operationg Country</i>
                      </th>
                      <td>
                        <i>Indonesia, the Philippines, Vietnam</i>
                      </td>
                      <td>
                        <i>No limitation</i>
                      </td>
                      <td>
                        <i>No limitation</i>
                      </td>
                      <td>
                        <i>No limitation</i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>Period of Residence</i>
                      </th>
                      <td>
                        <i>
                          4 years（No limitation after obtaining a Certified
                          Care Worker qualification）
                        </i>
                      </td>
                      <td>
                        <i>No limitation</i>
                      </td>
                      <td>
                        <i>
                          5 years maximum <sup>※8</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          5 years maximum <sup>※8</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>Accompaniment of Family Members</i>
                      </th>
                      <td>
                        <i>
                          Family members（spouse/child）<sup>※1</sup>
                        </i>
                      </td>
                      <td>
                        <i>Family members（spouse/child）</i>
                      </td>
                      <td>
                        <i>N/A</i>
                      </td>
                      <td>
                        <i>N/A</i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>Japanese Language Skills</i>
                      </th>
                      <td>
                        <i>
                          N3 <sup>※2</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          N2 <sup>※5</sup>
                        </i>
                      </td>
                      <td>
                        <i>N4</i>
                      </td>
                      <td>
                        <i>
                          CFER2、Japanese language skills necessary for working
                          in long-term care<sup>※10</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          Qualification and Study Experience in Their Home
                          Country
                        </i>
                      </th>
                      <td>
                        <i>
                          Graduate of nursing related school or has government
                          certification as care worker in home country
                        </i>
                      </td>
                      <td>
                        <i>Depending on the individual</i>
                      </td>
                      <td>
                        <i>Depending on the supervising organizations</i>
                      </td>
                      <td>
                        <i>
                          Individual with specific knowledge/skills{" "}
                          <sup>※11</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          Requirement to Take Certified Care Worker National
                          Examination
                        </i>
                      </th>
                      <td>
                        <i>
                          Yes <sup>※3</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          Yes <sup>※6</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          N/A <sup>※9</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          N/A <sup>※9</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>Acceptance Support and Support Organizations</i>
                      </th>
                      <td>
                        <i>JICWELS（International Welfare Agency）</i>
                      </td>
                      <td>
                        <i>N/A</i>
                      </td>
                      <td>
                        <i>Each supervising organization</i>
                      </td>
                      <td>
                        <i>Registered supporting organizations</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </OverflowFrame>

              <ul className={`notes`}>
                <li>
                  <i>※1</i>
                  <span>
                    After obtaining a Certified Care Worker qualification.
                  </span>
                </li>
                <li>
                  <i>※2</i>
                  <span>
                    Requirements at time of entry for the Philippines: N5 or
                    higher level, Indonesia: N4 or higher level, Vietnam: N3 or
                    higher level.
                  </span>
                </li>
                <li>
                  <i>※3</i>
                  <span>
                    As an exception, individuals who fail the exam but satisfy
                    certain scores may have residence extended for one year only
                    and take the examination again.
                  </span>
                </li>
                <li>
                  <i>※4</i>
                  <span>
                    Provided. However, ‘Student’ before obtaining a Certified
                    Care Worker qualification.
                  </span>
                </li>
                <li>
                  <i>※5</i>
                  <span>Some schools require N2 or higher level.</span>
                </li>
                <li>
                  <i>※6</i>
                  <span>
                    Individuals who graduated between April 2017 and March 2022
                    can obtain a Certified Care Worker qualification either by
                    passing the national examination within five years, or
                    engaging in long-term care for five years after graduation.
                  </span>
                </li>
                <li>
                  <i>※7</i>
                  <span>
                    Year 1: ‘Technical Intern Training (i)’ Year 2-3: ‘Technical
                    Intern Training (ii)’ Year 4-5: ‘Technical Intern Training
                    (iii)’
                  </span>
                </li>
                <li>
                  <i>※8</i>
                  <span>
                    Provided. However, the status of residence ‘Nursing Care’
                    can be selected when certification is obtained, enabling
                    permanent employment. A technical intern trainee who
                    completed the third year is exempted from the examination
                    for obtaining ‘Specific Skilled Worker (i)’ (if the status
                    of residence is changed, the maximum period of residence
                    becomes ten years, with technical intern and specified
                    skills).
                  </span>
                </li>
                <li>
                  <i>※9</i>
                  <span>
                    Status of residence may be converted to ‘Nursing Care’ by
                    obtaining a Certified Care Worker qualification.
                  </span>
                </li>
                <li>
                  <i>※10</i>
                  <span>
                    Must pass ‘Japan Foundation Test for Basic Japanese
                    (JFT-Basic)’ developed by The Japan Foundation, and ‘Nursing
                    Care Japanese Language Evaluation Test’ developed by the
                    Japan Ministry of Health, Labor and Welfare. An individual
                    with JLPT N4 or higher level is exempted from JFT-Basic.
                  </span>
                </li>
                <li>
                  <i>※11</i>
                  <span>
                    Must pass ‘Nursing Care Skills Evaluation Test’ developed by
                    the Japan Ministry of Health, Labor and Welfare.
                  </span>
                </li>
              </ul>
              <a
                className={`mb60`}
                href={`/assets/images/basic_info/basic_info_kaigo_4type_en.pdf`}
                target={`_blank`}
              >
                PDF DOWNLOAD (81KB)
              </a>

              <h4>The Steps Required until Recruitment</h4>
              <p>
                According to a status of residence chosen, the steps you need to
                take until recruitment differ. If you obtain ‘EPA’ or ‘Nursing
                Care’ with a national qualification for Certified Care Worker,
                you can work permanently in Japan. As for ‘Technical Intern
                Training’ and ‘Specified Skilled Worker,’ you will be able to
                utilize the skills you obtained while you were staying in Japan
                when you go back to your country.
              </p>

              <OverflowFrame>
                <img
                  src={`/assets/images/basic_info/basic_info_kaigo_flow_en.png`}
                  alt="The Steps Required until Recruitment"
                />
              </OverflowFrame>

              <ul className={`notes`}>
                <li>
                  <i>※1</i>
                  <span>
                    Graduates between April 2017 and March 2022 are required to
                    pass the national examination within five years, or engage
                    in long-term care for five years after graduation.
                  </span>
                </li>
                <li>
                  <i>※2</i>
                  <span>
                    {" "}
                    The course within the dotted lines is to be established in
                    the future.
                  </span>
                </li>
                <li>
                  <i>※3</i>
                  <span>
                    Those who entered into Japan as EPA Certified Care Worker
                    candidates and worked or trained appropriately for four
                    years are exempted from skill test and Japanese proficiency
                    test which are required for qualification as a Specific
                    Skilled Worker (i).
                  </span>
                </li>
              </ul>
              <a
                href={`/assets/images/basic_info/basic_info_kaigo_flow_en.pdf`}
                target={`_blank`}
              >
                PDF DOWNLOAD (80KB)
              </a>
            </BasicText>
          </div>
        </CenterContentLayout>
      </LayoutBasic>
    </div>
  )
}
export default Kaigo
